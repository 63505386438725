<template>
  <div class="card p-shadow-6">
    <h1>Portfólio {{ showBy }}</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <div style="height: 65vh">
      <DataTable
        :value="portfolio"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="category"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
        groupRowsBy="category"
        rowGroupMode="subheader"
        :expandableRowGroups="true"
        :expandedRowGroups.sync="expandedRowGroups"
      >
        <template #loading>
          Selecione em cima se pretende ver por Áreas de Atuação ou
          Solução</template
        >
        <template #header>
          <div class="table-header">
            <Button
              label="Áreas de Atuação"
              @click="showBy = 'Áreas de Atuação'"
            />
            <Button
              label="Soluções"
              class="p-ml-2"
              @click="showBy = 'Soluções'"
            />
          </div>
        </template>
        <template #groupheader="slotProps">
          <div class="myGroupHeader">
            {{ slotProps.data.category }}
          </div>
        </template>
        <Column
          sortable
          field="name"
          header="Nome"
          filterField="name"
          :headerStyle="{ 'min-width': '30%', 'max-width': '30%' }"
          :filterHeaderStyle="{ 'min-width': '30%', 'max-width': '30%' }"
          :bodyStyle="{ 'min-width': '30%', 'max-width': '30%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Ver Classificação'"
              @click="viewClassification(slotProps.data.id)"
              >{{ slotProps.data.name }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          header="Categoria"
          filterField="category"
          :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.category }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="case_study"
          header="Caso de Estudo"
          filterField="case_study"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.case_study == 1 ? "Sim" : "Não" }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          sortable
          field="seller.username"
          header="Vendedor"
          filterField="seller.username"
          :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.seller.username }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import businessService from "../services/business.service";
import { getOnlyDate } from "../helpers/helpers";

export default {
  name: "portfolio",
  components: {},
  watch: {
    showBy() {
      if (this.showBy === "Áreas de Atuação") {
        this.getPortfolioByAreas();
      } else if (this.showBy === "Soluções") {
        this.getPortfolioBySolutions();
      }
    },
  },
  data() {
    return {
      portfolio: [],
      bySolutions: [],
      bySolutionsItems: [],
      byBusinessArea: [],
      byBusinessAreaItems: [],
      expandedRowGroups: [],
      showBy: null,
      loading: false,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        case_study: { value: null, matchMode: FilterMatchMode.EQUALS },
        "seller.username": { value: null, matchMode: FilterMatchMode.CONTAINS },
        category: { value: null, matchMode: FilterMatchMode.CONTAINS },
        client: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  async created() {},
  methods: {
    getDate(date) {
      return `${getOnlyDate(date)}`;
    },
    async getPortfolioByAreas() {
      if (this.byBusinessArea.length === 0) {
        this.loading = true;
        this.byBusinessArea = await businessService.getPortfolioByAreas();
        this.byBusinessArea.map((area) => {
          area.items.map((item) => {
            item.category = area.name;
            this.byBusinessAreaItems.push(item);
          });
        });
        this.loading = false;
      }
      this.expandedRowGroups = this.byBusinessArea.map((area) => area.name);
      return (this.portfolio = this.byBusinessAreaItems);
    },
    async getPortfolioBySolutions() {
      if (this.bySolutions.length === 0) {
        this.loading = true;
        this.bySolutions = await businessService.getPortfolioBySolutions();
        this.bySolutions.map((area) => {
          area.items.map((item) => {
            item.category = area.name;
            this.bySolutionsItems.push(item);
          });
        });
        this.loading = false;
      }
      this.expandedRowGroups = this.bySolutions.map((area) => area.name);
      return (this.portfolio = this.bySolutionsItems);
    },
    viewClassification(id) {
      let route = this.$router.resolve({
        path: `/business/classification/${id}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style>
.p-rowgroup-header {
  z-index: 0 !important;
}
.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
  background-color: #007bff;
  color: #fff;
}
</style>
